import {
  AppBar,
  Box,
  CircularProgress,
  IconButton,
  SxProps,
  Theme,
  ThemeProvider,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { sendGTMEvent } from '@next/third-parties/google'
import { jwtDecode } from 'jwt-decode'
import mixpanel from 'mixpanel-browser'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import {
  ElementType,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useElementSize } from 'usehooks-ts'
import { auth } from 'lib/auth'
import { route } from 'lib/constants'
import { colors } from 'lib/root'
import { darkTheme, lightTheme } from 'lib/theme'
import { camelCase, isNotBoston, toSxArray } from 'lib/utils'
import usePatientFetcher from 'components/hooks/usePatientFetcher'
import BurgerButton from './BurgerButton'
import CTAButton from './CTAButton'
import Link from './Link'
import Logo from './Logo'
import WebViewCloseButton from './WebViewCloseButton'
import { AppContext } from './contexts/AppContext'
import LeftLongArrow from './icons/LeftLongArrow'

const MobileMenu = dynamic(() => import('./MobileMenu'))

interface Props {
  slim?: boolean
  useDarkTheme?: boolean
  backHref?: string
  showBackButton?: boolean
  clickableLogo?: boolean
  profileHeader?: boolean
  transparent?: boolean
  children?: ReactNode
  hideLogin?: boolean
  sx?: SxProps<Theme>
  component?: ElementType<any>
  logoColor?: string
  bookButtonHref?: string
}

const Header: FunctionComponent<Props> = ({
  slim = false,
  useDarkTheme = false,
  showBackButton = false,
  backHref,
  clickableLogo = true,
  profileHeader = false,
  children,
  hideLogin = false,
  sx,
  component,
  logoColor = '',
  bookButtonHref,
}) => {
  const {
    userEmail,
    setUserEmail,
    mobileMenuIsShown,
    toggleMobileMenuIsShown,
    setHeaderHeight,
    isWebView,
  } = useContext(AppContext)
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const router = useRouter()
  const [headerRef, { height }] = useElementSize()
  const pastTopOfPage = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  useEffect(() => {
    setHeaderHeight(height)

    return () => setHeaderHeight(0)
  }, [height, setHeaderHeight])

  const toolbarHeight = !pastTopOfPage ? 96 : 72

  useEffect(() => {
    if (slim) {
      return
    }

    setHeaderHeight(toolbarHeight)
  }, [slim, toolbarHeight, setHeaderHeight])

  useEffect(() => {
    const idToken = auth(null, false)
    const { email } = idToken ? (jwtDecode(idToken) as any) : ''
    setUserEmail(email)
  }, [setUserEmail])

  const isLoggedIn = !!userEmail

  const { data: patient } = usePatientFetcher()

  const trackingClick = (event: string) => {
    mixpanel.track(event)
  }

  const getInitials = (patient) => {
    const { display_name, last_name } = patient.person
    const initials = display_name.charAt(0) + last_name.charAt(0)
    return initials.toUpperCase()
  }

  const handleNewNavClick = (button: 'Ortho' | 'Emergency') => {
    sendGTMEvent({
      event: 'Navigation_Test',
      button,
    })
  }

  const isBooking = router.asPath.includes('/booking')
  const isLoginPage = router.pathname === route.login
  const showLogin = !isLoggedIn && !isBooking && !isLoginPage && !hideLogin
  const profileIsActive = isLoggedIn && profileHeader
  const isMyMouth = router.pathname.includes(route.myMouth)
  const isIntake = router.pathname.includes(route.intake)
  // separate check makes /booking/markets and /booking/ortho-markets both included
  const isMarketSelectionPage =
    router.pathname.includes('/booking') && router.pathname.includes('markets')

  const logoUrl = useMemo(() => {
    if (isLoggedIn && !isWebView) {
      return '/profile'
    }

    return '/'
  }, [isLoggedIn, isWebView])

  const logoStyles = ({
    isLink,
    isSlim,
    color,
  }: {
    isLink?: boolean
    isSlim?: boolean
    color?: string
  }) => [
    {
      display: 'flex',
      color: colors.core.grey[600],
      '& svg': {
        width: { xs: 68, sm: 90 },
        height: { xs: 24, sm: 32 },
        fill: 'currentColor',
      },
    },
    useDarkTheme && {
      color: colors.defaults.white,
    },
    isLink && {
      '&:hover': {
        color: colors.core.blue[800],
      },
      '&:active': {
        color: colors.core.blue[800],
      },
    },
    isLink &&
      useDarkTheme && {
        '&:hover': {
          color: colors.defaults.white,
          opacity: 0.8,
        },
        '&:active': {
          color: colors.core.green[500],
        },
      },
    isSlim && { justifyContent: 'center' },
    color === 'white' && {
      color: colors.defaults.white,
      '&:hover': {
        color: colors.defaults.white,
        opacity: 0.8,
      },
    },
    color === 'green' && {
      color: colors.core.green[800],
      '&:hover': {
        color: colors.core.green[800],
        opacity: 0.8,
      },
    },
    color === 'brown' && {
      color: colors.core.grey[700],
      '&:hover': {
        color: colors.core.grey[800],
        opacity: 0.8,
      },
    },
  ]

  const profileButtonStyles = [
    {
      color: colors.core.green[700],
      border: `1.5px solid ${colors.core.green[700]}`,
      ml: 2,
      fontSize: '0.8125rem',
      width: 30,
      height: 36,
      borderRadius: 48,
      padding: 0,
      lineHeight: 1.5,
      letterSpacing: '0.06em',
      fontWeight: 500,
      '&:hover': {
        backgroundImage: 'url("/tend-avatar-1.gif")',
        textDecoration: 'none',
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        '& path': {
          stroke: theme.palette.common.white,
        },
      },
    },
    useDarkTheme && {
      color: colors.core.paper,
      borderColor: colors.core.paper,
      '& path': {
        stroke: theme.palette.common.white,
      },
    },
  ]

  const navLinksStyles = [
    {
      display: 'flex',
      alignItems: 'center',
      '& a': {
        position: 'relative',
        color: theme.palette.secondary.main,
        fontSize: '1.125rem',
        fontWeight: 500,
        '&:hover': {
          color: colors.core.blue[800],
        },
        '&:active, &.active': {
          color: colors.core.blue[800],
        },
      },
      '& a + a': {
        pl: 3,
      },
    },
    useDarkTheme && {
      '& a': {
        color: colors.core.paper,
      },
    },
    mobileMenuIsShown && {
      display: 'none',
    },
  ]

  const mdUpLinksStyles = { ml: 5, display: { xs: 'none', md: 'flex' } }

  const progressStyles = [
    { position: 'absolute', color: colors.core.green[700] },
    useDarkTheme && { color: colors.core.paper },
  ]

  const actionsStyles = {
    display: 'flex',
    alignItems: profileHeader ? 'center' : 'auto',
    ml: 'auto',
  }

  const determineColor = () => {
    if ((!profileIsActive && !pastTopOfPage && !slim) || mobileMenuIsShown) {
      return 'transparent'
    }

    return 'primary'
  }

  return (
    <ThemeProvider theme={useDarkTheme ? darkTheme : lightTheme}>
      <AppBar
        ref={headerRef}
        component={component ?? 'header'}
        position={mobileMenuIsShown ? 'fixed' : 'sticky'}
        color={determineColor()}
        elevation={0}
        sx={[{ transition: 'background-color 0.5s ease' }, ...toSxArray(sx)]}
      >
        {slim ? (
          <Toolbar
            sx={[
              {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                borderBottom: `1px solid ${colors.core.grey[400]}`,
                color: theme.palette.common.white,
                py: 0,
                px: { xs: 3, md: 3 },
                transition: 'height 0.2s ease-out',
                height: 80,
                maxHeight: { xs: 72, md: null },
              },
              useDarkTheme && { borderBottomColor: 'transparent' },
            ]}
          >
            <div>
              {isWebView && isMarketSelectionPage && (
                <Box
                  sx={{
                    position: 'absolute',
                    width: 48,
                    top: 12,
                    left: 8,
                  }}
                >
                  <WebViewCloseButton />
                </Box>
              )}

              {showBackButton && backHref && (
                <IconButton
                  aria-label="back"
                  component={Link}
                  href={backHref || '/'}
                  size="large"
                  sx={[
                    {
                      width: 48,
                      height: 48,
                      padding: 0,
                      marginLeft: theme.spacing(-1),
                      color: colors.core.grey[700],
                      '&:hover path': {
                        stroke: colors.core.blue[800],
                      },
                      '&:active path': {
                        stroke: colors.core.blue[875],
                      },
                    },
                    useDarkTheme && {
                      color: colors.core.paper,
                      '&:hover': {
                        bgcolor: theme.palette.common.white,
                        borderColor: theme.palette.common.white,
                        color: colors.core.blue[800],
                        '& path': {
                          stroke: colors.core.blue[800],
                        },
                      },
                    },
                  ]}
                >
                  <LeftLongArrow size="md" />
                </IconButton>
              )}
            </div>

            {!isWebView && (
              <>
                {clickableLogo || isLoggedIn ? (
                  <Link
                    href={logoUrl}
                    underline="none"
                    aria-label="Tend Home"
                    sx={logoStyles({ isLink: true, isSlim: true })}
                  >
                    <Logo />
                  </Link>
                ) : (
                  <Box sx={logoStyles({ isSlim: true })}>
                    <Logo role="presentation" />
                  </Box>
                )}
              </>
            )}

            {!isWebView && !isIntake && (
              <Box sx={actionsStyles}>
                {showLogin && (
                  <Box sx={navLinksStyles}>
                    <Link href={route.login} underline="none">
                      Log in
                    </Link>
                  </Box>
                )}
                {isLoggedIn && (
                  <IconButton
                    component={Link}
                    href={
                      mdUp ? '/profile/settings/my-info' : '/profile/settings'
                    }
                    size="large"
                    sx={profileButtonStyles}
                  >
                    {patient ? (
                      getInitials(patient)
                    ) : (
                      <CircularProgress size={20} sx={progressStyles} />
                    )}
                  </IconButton>
                )}
              </Box>
            )}
          </Toolbar>
        ) : (
          <Toolbar
            sx={{
              display: 'flex',
              color: theme.palette.common.white,
              py: 0,
              px: { xs: 3, md: 8 },
              padding: theme.spacing(0, 3),
              transition: 'height 0.2s linear',
              maxHeight: { xs: 72, md: null },
              height: toolbarHeight,
            }}
          >
            <Link
              href={logoUrl}
              underline="none"
              aria-label="Tend"
              sx={[
                ...logoStyles({
                  isLink: true,
                  color: camelCase(logoColor),
                }),
                mobileMenuIsShown && { color: theme.palette.common.white },
              ]}
            >
              <Logo />
            </Link>

            {profileHeader ? (
              <Box
                component="nav"
                aria-label="primary"
                sx={[...navLinksStyles, mdUpLinksStyles]}
              >
                <Link href="/profile" underline="none">
                  Home
                </Link>
                <Link href="/profile/health" underline="none">
                  Treatment
                </Link>
                <Link href="/profile/appointments" underline="none">
                  Visits
                </Link>
                {patient && isNotBoston(patient?.person?.market) && (
                  <Link
                    href="/profile/invite-a-friend"
                    underline="none"
                    onClick={() =>
                      trackingClick('Profile Invite A Friend Selected')
                    }
                  >
                    Invite a Friend
                  </Link>
                )}
              </Box>
            ) : (
              <Box
                component="nav"
                aria-label="primary"
                sx={[...navLinksStyles, mdUpLinksStyles]}
              >
                <Link href="/insurance" underline="none">
                  Insurance
                </Link>
                <Link href="/studios" underline="none">
                  Locations
                </Link>
                <Link href="/services" underline="none">
                  Services
                </Link>
                <Link
                  href="/orthodontics"
                  underline="none"
                  onClick={() => handleNewNavClick('Ortho')}
                >
                  Orthodontics
                </Link>
                <Link
                  href="/emergency"
                  underline="none"
                  onClick={() => handleNewNavClick('Emergency')}
                >
                  Emergency
                </Link>
              </Box>
            )}
            <Box sx={actionsStyles}>
              <Box component="nav" aria-label="secondary" sx={navLinksStyles}>
                {!isLoggedIn && (
                  <Link
                    href={route.login}
                    underline="none"
                    sx={{ pr: 3, display: { xs: 'none', md: 'block' } }}
                  >
                    Log in
                  </Link>
                )}
                {isLoggedIn && !profileHeader && (
                  <Link href={route.profile} underline="none" sx={{ pr: 3 }}>
                    My Profile
                  </Link>
                )}
              </Box>
              <ThemeProvider
                theme={
                  mobileMenuIsShown || useDarkTheme ? darkTheme : lightTheme
                }
              >
                {!isMyMouth && (
                  <CTAButton
                    sx={[
                      {
                        py: { xs: 1, md: 1.5 },
                        px: { xs: 3, md: 2.5 },
                      },
                      profileHeader && { height: 40 },
                    ]}
                    href={bookButtonHref}
                    id="header-book-button"
                  >
                    {!mdUp || profileHeader ? 'Book' : 'Book Now'}
                  </CTAButton>
                )}
                {isLoggedIn &&
                  !isWebView &&
                  profileHeader &&
                  !mobileMenuIsShown && (
                    <IconButton
                      component={mdUp ? Link : undefined}
                      href={mdUp ? '/profile/settings/my-info' : undefined}
                      onClick={mdUp ? undefined : toggleMobileMenuIsShown}
                      edge="end"
                      aria-label="profile settings button"
                      id="header-profile-button"
                      size="large"
                      sx={profileButtonStyles}
                    >
                      {patient ? (
                        getInitials(patient)
                      ) : (
                        <CircularProgress size={20} sx={progressStyles} />
                      )}
                    </IconButton>
                  )}
                {(mobileMenuIsShown || !profileIsActive) && (
                  <BurgerButton
                    aria-label="menu"
                    onClick={toggleMobileMenuIsShown}
                    active={mobileMenuIsShown}
                    width={20}
                    color={
                      mobileMenuIsShown || useDarkTheme
                        ? theme.palette.common.white
                        : colors.core.grey[700]
                    }
                    lineHeight={2}
                    lineSpacing={3}
                    padding="0"
                    sx={[
                      {
                        pl: '16px !important',
                        display: { md: 'none !important' },
                      },
                    ]}
                  />
                )}
                <MobileMenu
                  open={mobileMenuIsShown}
                  isLoggedIn={isLoggedIn}
                  profileHeader={profileHeader}
                />
              </ThemeProvider>
            </Box>
          </Toolbar>
        )}
        {children}
      </AppBar>
    </ThemeProvider>
  )
}

export default Header
