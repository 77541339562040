import { Box, ButtonBase, IconButton, SxProps, Theme } from '@mui/material'
import { colors } from 'lib/root'
import { sendMessageToApp, toSxArray } from 'lib/utils'
import TendButton from './TendButton'
import CloseIcon from './icons/CloseIcon'
import LeftLongArrow from './icons/LeftLongArrow'

interface Props {
  type?: 'button' | 'backArrow' | 'x'
  styled?: boolean
  relative?: boolean
  handleClick?: () => void
  sx?: SxProps<Theme>
}

const WebViewCloseButton = ({
  type = 'x',
  relative = false,
  handleClick,
  styled = false,
  sx = [],
}: Props) => {
  const closeWebView = () => {
    if (handleClick) {
      return handleClick()
    }
    sendMessageToApp({ type: 'needs_to_close_webview' })
  }

  if (type === 'button') {
    return (
      <Box
        sx={{
          position: 'fixed',
          width: 'auto',
          bottom: 20,
          left: 24,
          right: 24,
          ...toSxArray(sx),
        }}
      >
        <TendButton size="large" fullWidth long onClick={closeWebView}>
          Finish
        </TendButton>
      </Box>
    )
  }

  if (type === 'backArrow') {
    return (
      <ButtonBase
        aria-label="Go back"
        sx={{
          position: 'absolute',
          ...(styled && {
            zIndex: 1,
            backgroundColor: colors.defaults.white,
            borderRadius: '50%',
            left: 12,
            top: 40,
          }),
        }}
        onClick={closeWebView}
      >
        <LeftLongArrow size="md" />
      </ButtonBase>
    )
  }

  return (
    <IconButton
      size="small"
      aria-label="Close"
      onClick={closeWebView}
      sx={{
        position: relative ? 'relative' : 'absolute',
        right: relative ? 13 : 0,
        fontSize: '2.5rem',
        color: colors.core.grey[700],
        '&:active': {
          color: colors.core.blue[800],
        },
        ...toSxArray(sx),
      }}
    >
      <CloseIcon fontSize="inherit" color="inherit" />
    </IconButton>
  )
}

export default WebViewCloseButton
