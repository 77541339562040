import { Button, ButtonProps } from '@mui/material'
import { FunctionComponent } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { route } from 'lib/constants'
import { isAnchorLink, toSxArray } from 'lib/utils'
import Link from './Link'

export interface CTAButtonProps extends ButtonProps<'a'> {
  long?: boolean
}

const CTAButton: FunctionComponent<CTAButtonProps> = ({
  href = route.booking,
  children = 'Book Now',
  variant = 'contained',
  long = false,
  sx,
  ...other
}) => (
  <Button
    {...other}
    color="primary"
    variant={variant}
    component={isAnchorLink(href) ? AnchorLink : Link}
    offset={isAnchorLink(href) ? 72 : null}
    href={href}
    sx={[
      long && {
        height: { xs: '64px !important', md: '80px !important' },
        minWidth: 328,
        fontSize: { md: '1.25rem' },
      },
      ...toSxArray(sx),
    ]}
  >
    {children}
  </Button>
)

export default CTAButton
