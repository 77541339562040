import { SvgIcon } from '@mui/material'
import { iconSizes, iconColors } from 'lib/constants'
import { colors } from 'lib/root'

const LeftArrow = (props) => (
  <SvgIcon
    viewBox="0 0 40 40"
    sx={{
      transform: props.rotate ? `rotate(${props.rotate}deg)` : 'rotate(0)',
      fill: 'none',
      fontSize: `${iconSizes[props.size || 'xs']}rem !important`,
    }}
    {...props}
  >
    <path
      d="M24 12.0001L16 20.0001L24 28.0001"
      className="left-arrow"
      stroke={
        iconColors[props.color || 'inherit'] ||
        props.color ||
        colors.core.grey[700]
      }
      strokeWidth={props.strokeWidth || '1.5'}
    />
  </SvgIcon>
)

export default LeftArrow
