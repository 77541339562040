import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { CircularProgress } from '@mui/material'
import { useRouter } from 'next/router'
import { FunctionComponent, useContext } from 'react'
import { colors } from 'lib/root'
import { toSxArray } from 'lib/utils'
import { AppContext } from './contexts/AppContext'

interface Props extends LoadingButtonProps {
  long?: boolean
}

const TendButton: FunctionComponent<Props> = ({
  variant = 'contained',
  long = false,
  children = 'Continue',
  size,
  sx,
  ...other
}) => {
  const router = useRouter()
  const isIntake = router?.pathname.includes('/intake')
  const { isWebView } = useContext(AppContext)
  const isIntakeInApp = isIntake && isWebView

  return (
    <LoadingButton
      aria-label={`${children}`}
      color="primary"
      variant={variant}
      size={size}
      loadingIndicator={
        <CircularProgress
          color="inherit"
          size={size === 'large' || long ? 48 : 32}
        />
      }
      sx={[
        long && {
          height: '64px !important',
          fontSize: '1.125rem !important',
          px: { xs: 2.5, md: 'unset' },
          minWidth: { sm: 328 },
        },
        isIntakeInApp && {
          bgcolor: 'transparent',
          color: colors.core.green[600],
          border: `1px solid ${colors.core.green[600]}`,
          '&:hover, &:focus, &:active': {
            color: colors.defaults.white,
            border: `1px solid ${colors.core.green[600]}`,
            bgcolor: colors.core.green[600],
          },
        },
        ...toSxArray(sx),
      ]}
      {...other}
    >
      {children}
    </LoadingButton>
  )
}

export default TendButton
