import { Box, Container, SxProps, Theme } from '@mui/material'
import { FunctionComponent, ReactNode, useContext, useEffect } from 'react'
import { useElementSize } from 'usehooks-ts'
import { colors } from 'lib/root'
import { camelCase, toSxArray } from 'lib/utils'
import { AppContext } from 'components/contexts/AppContext'
import Link from './Link'
import LeftArrow from './icons/LeftArrow'

interface BannerProps {
  children: ReactNode
  href?: string
  uppercase?: boolean
  showChevron?: boolean
  color: string
  sx?: SxProps<Theme>
}

const Banner: FunctionComponent<BannerProps> = ({
  children,
  uppercase = false,
  showChevron = false,
  color = 'green',
  href,
  sx,
}) => {
  const [ref, { height }] = useElementSize()
  const { setBannerHeight } = useContext(AppContext)

  useEffect(() => {
    setBannerHeight(height)
    return () => setBannerHeight(0)
  }, [height, setBannerHeight])

  const bannerStyles = {
    green: {
      bgcolor: colors.core.green[600],
    },
    brown: {
      bgcolor: colors.core.grey[800],
    },
    red: {
      bgcolor: colors.utility.red[700],
    },
    yellow: {
      bgcolor: colors.ortho.citron[300],
      color: colors.core.green[700],
      '&:hover': {
        bgcolor: `${colors.ortho.citron[300]} !important`,
      },
    },
  }

  return (
    <Box
      sx={[
        {
          display: 'block',
          position: 'relative',
          bgcolor: colors.core.green[600],
          color: colors.defaults.white,
          fontSize: { xs: '1rem', md: '1.125rem' },
          lineHeight: 1.2,
          '& strong': {
            fontWeight: 'normal',
          },
        },
        uppercase && {
          fontSize: { xs: '0.875rem', md: '1rem' },
          textTransform: 'uppercase',
          letterSpacing: '0.12em',
          '& strong': {
            fontWeight: 500,
          },
        },
        !!href && {
          '&:hover, &:active': {
            bgcolor: colors.core.blue[800],
          },
          '& a': {
            textDecoration: 'none',
          },
        },
        bannerStyles[camelCase(color)],
        ...toSxArray(sx),
      ]}
      component={!!href ? Link : undefined}
      {...(href ? { href } : undefined)}
      {...(href ? { underline: 'none' } : undefined)}
    >
      <Container
        ref={ref}
        maxWidth="xl"
        sx={[
          {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: { xs: 1.5, sm: 2 },
            px: { xs: 3, md: 8 },
            '& > div > span': {
              display: { xs: 'block', sm: 'inline' },
            },
          },
          showChevron && {
            justifyContent: { xs: 'space-between', sm: 'center' },
            pr: { xs: 2, sm: 8 },
          },
        ]}
      >
        <div>{children}</div>
        {showChevron && <LeftArrow rotate={180} size="xs" role="img" />}
      </Container>
    </Box>
  )
}

export default Banner
